import axios from 'axios';
import config from '../config';
import store from 'store';

const axiosRequest = axios.create({
    baseURL: config.apiUrl
});

axiosRequest.interceptors.request.use((axiosConfig) => {
    const token = store.get(config.storeKey.token);
    axiosConfig.headers.Authorization = 'Bearer ' + token;
    return axiosConfig;
});

export const setAxiosToken = (token) => {
    if (token) {
        axiosRequest.defaults.headers.common.Authorization = 'Bearer ' + token;
    }
    axiosRequest.defaults.headers.common.Authorization = null;
};

export default axiosRequest;
