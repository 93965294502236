const getApiURL = (windowLocation) => {
	if(windowLocation === 'localhost' || windowLocation === '127.0.0.1'){ // Localhost
		return 'http://127.0.0.1:8000/apiv2/';
	} else if (windowLocation === 'stag-nissan-lp.gd.ro'){ // Staging
		return 'https://api-stag-nissan-lp.gd.ro/apiv2/';
	} else if (windowLocation === 'preprod-nissan.gd.ro' || windowLocation === 'nissan.gd.ro'){ // Preprod
		return 'https://api-nissan.gd.ro/apiv2/';
	} else if (windowLocation === 'campanii.nissan.ro'){ // Production
		return 'https://api-nissan.gd.ro/apiv2/';
	}
};

export default {
    apiUrl: getApiURL(window.location.hostname),
    storeKey: {
        token: 'token'
    },
    path: {
        index: '/',
        success: '/succes',
        regulation: '/regulament'
    },
    analyticsID: 'UA-6392056-1',
    dateFormat: 'mm/d/Y'
};
