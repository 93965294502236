import React from "react";
import styles from "./Footer.module.scss";

import image from "../../assets/images/new-juke-back.png";

class Footer extends React.Component{
    render() {
        return(
            <div className={styles.footer}>
                <div className={styles.container}>
                    {/*<h2><b>O experiență <span>all-inclusive</span></b> de o săptămână în România</h2>*/}
                    <img src={image} alt="Noul Nissan Juke"/>

                    <div className={styles.campaignDetails}>
                        <h3><b>O experiență all-inclusive</b> <span>de o săptămână în România</span></h3>
                        <p>Veștile bune nu se opresc aici. Toți participanții la test drive intră în cursa pentru marele premiu: o experiență all-inclusive de o săptămână cu Noul Juke în România!</p>
                    </div>
                </div>

                <div className={styles.bottomFooter} />
            </div>
        )
    }
}

export default Footer;
