import { combineReducers } from 'redux';
import configureStore from './createStore';
import rootSaga from './rootSaga';
import { connectRouter } from 'connected-react-router'
import { reducer as counties } from './Counties/Reducers';
import { reducer as leadGeneration } from './LeadGeneration/Reducers';

export default (history) => {
    const rootReducer = combineReducers({
        router: connectRouter(history),
        leadGeneration,
        counties
    });

    return configureStore(rootReducer, rootSaga);
};
