import React from 'react';
import Fade from "react-reveal/Fade";
import Header from "../../components/Header";
import styles from "../Success/Success.module.scss";

class Regulation extends React.Component {
    render() {
        return (
            <>
                <Header />

                <div className={styles.formWrapper}>
                    <Fade right>
                        <div className={styles.carImage}/>
                    </Fade>

                    <div className={styles.container}>
                        <Fade bottom>
                            <div className={styles.formDetails}>
                                <h2>Regulamentul Campaniei</h2>

                            </div>
                        </Fade>
                    </div>
                </div>
            </>
        );
    }
}

export default Regulation;
