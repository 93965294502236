import { all } from 'redux-saga/effects';
import countiesSaga from './Counties/Saga';
import leadGenerationSaga from './LeadGeneration/Saga';

export default function* root() {
    yield all([
        leadGenerationSaga(),
        countiesSaga()
    ]);
}
