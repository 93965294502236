import React from 'react';
import classnames from 'classnames';
import styles from './Form.module.scss';

// Components
import Fade from 'react-reveal/Fade';
import InputMask from 'react-input-mask';
import ReeValidate from 'ree-validate';
import {YearPicker, MonthPicker, DayPicker} from 'react-dropdown-date';

// PDFs
import notification from '../../assets/pdf/notificare-Nissan-2.pdf';
import regulation from '../../assets/pdf/regulament-oferta-speciala-voucher-noul-nissan-juke_v3_final.pdf';

// Saga
import {connect} from "react-redux";
import CountiesAction from "../../stores/Counties/Actions";
import LeadGenerationAction from "../../stores/LeadGeneration/Actions";

class Form extends React.Component {
    constructor(props) {
        super(props);

        let dictionary = {
            en: {
                messages: {
                    required: () => 'Acest câmp este obligatoriu.',
                    email: () => 'Te rugăm să introduci o adresă de email validă.',
                    numeric: () => 'Număr telefon invalid.',
                    alpha: () => 'Caracterele numerice nu sunt permise.'
                }
            }
        };

        this.validator = new ReeValidate({
            first_name: 'required',
            last_name: 'required',
            phone: 'required|numeric',
            email: 'required|email',
            city: 'required',
            is_company: 'required',
            has_tac: 'required',

            day: 'required',
            month: 'required',
            year: 'required'
        });

        this.validator.localize(dictionary);

        this.state = {
            errors: this.validator.errors,
            formData: {
                first_name: '',
                last_name: '',
                is_company: false,
                company_name: '',
                phone: '',
                email: '',
                city: '',
                has_marketing: '',
                has_tac: '',
                day: '',
                month: '',
                year: '',
                formatted_date: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.validateAndSubmit = this.validateAndSubmit.bind(this);
    }


    componentDidMount() {
        this.props.getCounties();
    }

    handleChange = (field, value) => {
        const {errors} = this.validator;
        errors.remove(field);

        value = (field === 'has_tac' || field === 'has_marketing') && value === false ? '' : value;

        this.setState((state) => ({
            ...state,
            formData: {
                ...state.formData,
                [field]: value
            }
        }));


        if (field !== 'has_marketing') {
            this.validator.validate(field, value)
                .then(() => {
                    this.setState({errors})
                });
        }
    };

    handleRadioChange = (field, value) => {
        if (field === 'is_company') {
            if (value === true) {
                this.validator.attach({
                    name: 'company_name',
                    rules: 'required'
                });
            } else {
                this.validator.detach('company_name');
            }
        }

        this.setState((state) => ({
            ...state,
            formData: {
                ...state.formData,
                [field]: value === true
            }
        }));
    };

    validateAndSubmit = (e) => {
        e.preventDefault();

        const {formData} = this.state;
        const {errors} = this.validator;

        this.validator.validateAll(formData)
            .then(success => {
                if (success) {
                    this.props.leadGeneration({
                        ...formData,
                        month: parseInt(formData.month) + 1,
                        formatted_date: `${parseInt(formData.month) + 1}/${formData.day}/${formData.year}`
                    });
                } else {
                    this.setState({errors})
                }
            });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const formErrors = this.props.formErrors;

        if (formErrors && prevProps.formErrors !== formErrors) {
            const {errors} = this.validator;
            for (let vKey in formErrors) {
                errors.add({
                    field: vKey,
                    msg: formErrors[vKey][0]
                });
            }
            this.setState({errors})
        }
    }

    render() {
        const {errors, formData} = this.state;
        const {loading, counties} = this.props;

        return (
            <div className={styles.formWrapper}>
                <Fade right>
                    <div className={styles.carImage}/>
                </Fade>

                <div className={styles.container}>
                    <Fade bottom>
                        <div className={styles.formDetails}>
                            <h2>Vă mulțumim pentru interes.</h2>
                            <h3>Acest Test Drive s-a încheiat, însă dacă doriți să fiți contactați pentru promoții și
                                oferte viitoare, vă rugăm să vă lăsați datele în formularul de mai jos.</h3>
                        </div>
                    </Fade>

                    <Fade bottom cascade>
                        <div className={classnames(styles.formContainer, loading ? styles.isLoading : '')}>
                            <form method="POST" className={styles.form} onSubmit={this.validateAndSubmit}>
                                <label className={classnames(styles.control, styles.controlRadio)}>
                                    <p><span>Persoana</span> fizică</p>
                                    <input type="radio" name="company" value={false} defaultChecked
                                           onChange={(e) => this.handleRadioChange('is_company', false)}/>
                                    <div className={styles.controlIndicator}/>
                                </label>

                                <label className={classnames(styles.control, styles.controlRadio)}>
                                    <p><span>Persoană</span> Juridică</p>
                                    <input type="radio" name="company" value={true}
                                           onChange={(e) => this.handleRadioChange('is_company', true)}/>
                                    <div className={styles.controlIndicator}/>
                                </label>

                                <label>
                                    <p>Nume</p>
                                    <input type="text" name="last_name" placeholder="ex: Ionescu"
                                           value={formData.last_name}
                                           onChange={(e) => this.handleChange('last_name', e.target ? e.target.value : e)}
                                    />
                                    {errors.has('last_name') &&
                                    <span className={styles.error}>{errors.first('last_name')}</span>}
                                </label>

                                <label>
                                    <p>Prenume</p>
                                    <input type="text" name="first_name" placeholder="ex: George"
                                           value={formData.first_name}
                                           onChange={(e) => this.handleChange('first_name', e.target ? e.target.value : e)}
                                    />
                                    {errors.has('first_name') &&
                                    <span className={styles.error}>{errors.first('first_name')}</span>}
                                </label>

                                {formData.is_company ?
                                    <Fade bottom cascade>
                                        <label className={styles.fullwidth}>
                                            <p>Nume Companie</p>
                                            <input type="text" name="company_name" placeholder="ex: sc companie.srl"
                                                   value={formData.company_name}
                                                   onChange={(e) => this.handleChange('company_name', e.target ? e.target.value : e)}
                                            />
                                            {errors.has('company_name') &&
                                            <span className={styles.error}>{errors.first('company_name')}</span>}
                                        </label>
                                    </Fade>
                                    : ''}

                                <div className={styles.grid}>
                                    <label>
                                        <p>E-mail</p>
                                        <input type="text" name="email" placeholder="ex: george.ionescu@gmail.com"
                                               value={formData.email}
                                               onChange={(e) => this.handleChange('email', e.target ? e.target.value : e)}
                                        />
                                        {errors.has('email') &&
                                        <span className={styles.error}>{errors.first('email')}</span>}
                                    </label>

                                    <label>
                                        <p>Telefon mobil</p>

                                        <InputMask mask="0\799999999" maskChar={null} placeholder="ex. 07xx xxx xxx"
                                                   name="phone"
                                                   value={formData.phone}
                                                   onChange={(e) => this.handleChange('phone', e.target ? e.target.value : e)}
                                        />
                                        {errors.has('phone') &&
                                        <span className={styles.error}>{errors.first('phone')}</span>}
                                    </label>

                                    <div className={styles.group}>
                                        <p>Data nașterii</p>

                                        <div>
                                            <label className={styles.selectable}>
                                                <p>Zi</p>
                                                <DayPicker
                                                    defaultValue={'Zi'}
                                                    year={formData.year}
                                                    month={formData.month}
                                                    endYearGiven
                                                    value={formData.day}
                                                    onChange={(day) => this.handleChange('day', day)}
                                                    id={'day'}
                                                    name={'day'}
                                                    classes={'classes'}
                                                    optionClasses={'option classes'}
                                                />
                                            </label>

                                            <label className={styles.selectable}>
                                                <p>Lună</p>
                                                <MonthPicker
                                                    defaultValue={'Luna'}
                                                    numeric short caps endYearGiven
                                                    year={formData.year}
                                                    value={formData.month}
                                                    onChange={(month) => this.handleChange('month', month)}
                                                    id={'month'}
                                                    name={'month'}
                                                    classes={'classes'}
                                                    optionClasses={'option classes'}
                                                />
                                            </label>

                                            <label className={styles.selectable}>
                                                <p>An</p>
                                                <YearPicker
                                                    defaultValue={'An'}
                                                    start={1940}
                                                    end={2001}
                                                    reverse
                                                    value={formData.year}
                                                    onChange={(year) => this.handleChange('year', year)}
                                                    id={'year'}
                                                    name={'year'}
                                                    classes={'classes'}
                                                    optionClasses={'option classes'}
                                                />
                                            </label>
                                        </div>

                                        {(errors.has('day') || errors.has('month') || errors.has('year')) &&
                                        <span className={styles.error}>Acest câmp este obligatoriu.</span>}
                                    </div>

                                    <label className={classnames(styles.city, styles.selectable)}>
                                        <p>Oraș</p>
                                        <select name="city"
                                                value={formData.city}
                                                onChange={(e) => this.handleChange('city', e.target ? e.target.value : e)}
                                        >
                                            <option value="" disabled>* Oraș</option>
                                            {counties.map((item) => (
                                                <option key={item.id} value={item.id}>{item.county_name}</option>))}
                                        </select>
                                        {errors.has('city') &&
                                        <span className={styles.error}>{errors.first('city')}</span>}
                                    </label>
                                </div>

                                <label className={classnames(styles.control, styles.controlCheckbox, styles.fullwidth)}>
                                    <input type="checkbox" name="has_tac"
                                           value={formData.has_tac}
                                           onChange={(e) => this.handleChange('has_tac', e.target ? e.target.checked : e)}
                                    />
                                    <div className={styles.controlIndicator}/>

                                    <p>Am citit <a href={regulation} target="_blank" rel="noopener noreferrer">notificarea privind protecția datelor cu caracter personal</a> și sunt de acord cu
                                        termenii și condițiile Nissan România.</p>
                                    {errors.has('has_tac') &&
                                    <span className={styles.error}>{errors.first('has_tac')}</span>}
                                </label>

                                <label className={classnames(styles.control, styles.controlCheckbox, styles.fullwidth)}>
                                    <input type="checkbox" name="has_marketing"
                                           value={formData.has_marketing}
                                           onChange={(e) => this.handleChange('has_marketing', e.target ? e.target.checked : e)}
                                    />
                                    <div className={styles.controlIndicator}/>

                                    <p>Dacă dorești să primești comunicări în scop de marketing și satisfacție client
                                        din partea societăților afiliate din grupul Renault România, te rugăm să bifezi
                                        această căsuță. Înainte, avem rugămintea să citești notificarea privind
                                        protecția datelor cu caracter personal <a href={notification} target="_blank" rel="noopener noreferrer">aici</a>.
                                    </p>
                                </label>

                                <button type="submit">Trimite</button>
                            </form>
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.leadGeneration.loading,
    formErrors: state.leadGeneration.errors,
    counties: state.counties.counties
});

const mapDispatchToProps = {
    getCounties: () => CountiesAction.counties(),
    leadGeneration: (params) => LeadGenerationAction.leadGeneration(params),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form);
