import React from "react";
import {Fade} from "react-reveal";
import config from "../../config";
import {Nav} from "react-bootstrap";
import styles from "./Header.module.scss";
import logo from "../../assets/images/logo.png";
import {LinkContainer} from "react-router-bootstrap";

class Header extends React.Component{
    state = {};

    render() {
        return(
            <div className={styles.header}>
                <div className={styles.container}>
                    <Fade top>
                        <LinkContainer to={config.path.index} className={styles.logo}>
                            <Nav.Link eventKey="">
                                <img src={logo} alt="Noul Nissan Juke - The Coupe Crossover"/>
                            </Nav.Link>
                        </LinkContainer>

                        <h1>
                            <span>Noul</span>
                            Nissan Juke
                            <small>The Coupe Crossover</small>
                        </h1>
                    </Fade>
                </div>
            </div>
        )
    }
}

export default Header;
