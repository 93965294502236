import { createReducer } from 'reduxsauce';
import { CountiesTypes } from './Actions';

const INITIAL_STATE = {
	counties: [],
	loading: false,
	errors: null
};

export const countiesLoading = (state) => ({
	...state,
	counties: [],
	loading: true,
	errors: null
});

export const countiesSuccess = (state, { counties }) => ({
	...state,
	counties: counties,
	loading: false,
	errors: null
});

export const countiesFail = (state, { errors }) => ({
	...state,
	counties: [],
	loading: false,
	errors: errors
});

export const reducer = createReducer(INITIAL_STATE, {
	[CountiesTypes.COUNTIES_LOADING]: countiesLoading,
	[CountiesTypes.COUNTIES_SUCCESS]: countiesSuccess,
	[CountiesTypes.COUNTIES_FAIL]: countiesFail
});
