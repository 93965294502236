import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
    leadGeneration: ['params'],
    leadGenerationLoading: null,
    leadGenerationSuccess: ['success'],
    leadGenerationFail: ['errors']
});

export const LeadGenerationTypes = Types;
export default Creators;
